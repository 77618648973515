import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, of } from "rxjs";
import { User } from "src/app/shared/interfaces/user";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { NavController } from "@ionic/angular";
import { FirebaseCollectionHelper } from "src/app/helpers/firebase-collections-helper";
import { RouteHelper } from "src/app/helpers/router-helper";
import * as firebase from "firebase";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AuthService {
    readonly firebaseUser$ = this._firebaseAuth.authState;

    readonly userId$ = this.firebaseUser$.pipe(
        map(fbUser => fbUser?.uid || null),
    );

    readonly user$ = this.userId$.pipe(
        switchMap(uid => this._fetchUserByAuthId(uid)),
        shareReplay({refCount: true, bufferSize: 1}),
    );

    constructor(
        private _db: AngularFirestore,
        private _firebaseAuth: AngularFireAuth,
        private _routeHelper: RouteHelper,
        private _navCtrl: NavController,
        private translate: TranslateService,
    ) {
    }

    getAuthToken(): Promise<string> {
        return firebase.default.auth().currentUser
            ? firebase.default.auth().currentUser.getIdToken()
            : null;
    }

    logout(): Promise<void> {
        localStorage.removeItem("auth.userId");
        return this._firebaseAuth.signOut();
    }

    async sendResetLink(email: string): Promise<void> {
        try {
            return await this._firebaseAuth.sendPasswordResetEmail(email);
        } catch (e) {
            return Promise.reject(e.code);
        }
    }

    async login(email: string, psw: string) {
        try {
            const credentials = await firebase.default.auth().signInWithEmailAndPassword(email, psw);
            // '_firebaseAuth' has an issue: cannot capture error
            // const credentials = await this._firebaseAuth.signInWithEmailAndPassword(
            //     email,
            //     psw
            // );

            await this._afterLogIn(credentials.user.uid);

            const user = await this._fetchUserByAuthId(credentials.user.uid)
                .pipe(take(1))
                .toPromise();

            if (user) {
                // localStorage.setItem(AUTH_USER_TYPE, user.userType);
                // this._setUserId(credentials.user);
                if (user) {
                    this._navCtrl.navigateRoot(this._routeHelper.homeUrl);
                } else {
                    throw new Error("user.not");
                }
            } else {
                await this._firebaseAuth.signOut();
                throw new Error("user.email.verified");
            }
        } catch (e) {
            if (e.code === 'auth/wrong-password') {
                return Promise.reject("Falsches password");
            } else if (e.code === 'auth/too-many-requests') {
                return Promise.reject("Konto temporär deaktiviert. Bitte versuchen Sie es später.");
            } else if (e.code === 'auth/user-not-found') {
                return Promise.reject("Benutzername nicht vorhanden");
            } else {
                return Promise.reject(e.code);
            }
        }
    }

    async signUp(user: User, psw: string): Promise<void> {
        const authUser = await firebase.default.auth().createUserWithEmailAndPassword(user.email, psw);
        // '_firebaseAuth' has an issue: cannot capture error
        // const authUser = await this._firebaseAuth.createUserWithEmailAndPassword(
        //     user.email,
        //     psw
        // );
        await this._db
            .collection<User>(FirebaseCollectionHelper.USERS)
            .doc(authUser.user.uid)
            .set(user);
        await this._afterLogIn(authUser.user.uid);
    }

    public waitAuth() {
        return this.userId$.pipe(
            filter(uid => !!uid),
            take(1),
        ).toPromise();
    }

    private _afterLogIn(uid: string) {
        localStorage.setItem("auth.userId", uid);

        const lang = this.translate.getBrowserLang();

        return this._db
            .collection<User>(FirebaseCollectionHelper.USERS)
            .doc(uid)
            .update({lang});
    }

    private _fetchUserByAuthId(authId: string): Observable<User> {
        if ( !authId) {
            return of<User>(null);
        }

        return this._db
            .doc<User>(FirebaseCollectionHelper.USERS + "/" + authId)
            .snapshotChanges()
            .pipe(
                map((user) => {
                    return {
                        $id: user.payload.ref,
                        ...(user.payload.data() as User),
                    };
                }),
            );
    }
}
