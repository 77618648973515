import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

    private _unsuscribeAll = new Subject();

    constructor(
        private _router: Router,
        private _platform: Platform,
    ) {

        this.initializeApp();
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this._unsuscribeAll.next();
        this._unsuscribeAll.complete();
    }

    async initializeApp() {
        this.firstPageReady()
            .subscribe(() => {
                SplashScreen.hide();
            });

        // await this._platform.ready();
        // if (this._platform.is('cordova')) {
        //     //this._statusBar.overlaysWebView(true);
        //     this._screenOrientation.lock(this._screenOrientation.ORIENTATIONS.PORTRAIT);
        // }

        // show splash screen instead of white screen
        // fromEvent(document, 'sh-reloading').subscribe(() => {
        //     this._splashScreen.show();
        // });
    }

    private firstPageReady(): Observable<any> {
        return this._router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                take(1));
    }
}
