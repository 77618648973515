import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { NotAuthGuard } from './guards/not-auth.guard';
import { SharedService } from './services/shared.service';
import { SafePipe } from './pipes/safe';
import { FbDatePipe } from "./pipes/fbdate.pipe";
import { HourstrPipe } from "./pipes/hourstr.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { ShCommonIonModule } from "../solhea/common-ion/sh-common-ion.module";
import { ShLoadingModule } from "../solhea/loading";
import { DurationPipe } from "./pipes/duration.pipe";

const sharedExports = [
    SafePipe,
    FbDatePipe,
    HourstrPipe,
    DurationPipe,
];

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,

        ShCommonIonModule,
        ShLoadingModule,
    ],
    declarations: sharedExports,
    exports: [
        ...sharedExports,
        ShCommonIonModule,
        ShLoadingModule,
    ],
})
export class SharedModule {
    // this is required for services to be a singleton
    // more: https://angular.io/guide/singleton-services#the-forroot-pattern
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AuthService,
                // system guards
                AuthGuard,
                NotAuthGuard,
                SharedService,
                SafePipe,
            ],
        };
    }
}
