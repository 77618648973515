import { Pipe, PipeTransform } from '@angular/core';


/**
 */
@Pipe({name: 'duration', pure: true})
export class DurationPipe implements PipeTransform {

    /**
     * @param value duration in seconds!
     */
    transform(value?: number): string | null {
        if (value === null || value === undefined) {
            return '';
        }
        const h = Math.floor(value / 3600);
        const m = Math.floor(value % 3600 / 60);
        const s = value % 60;
        // changed by markusin
        return `${h}:${this._zeropad(m)}:${this._zeropad(s)}`;
    }

    _zeropad(v: number) {
        if (v >= 0 && v < 10) {
            return '0' + v;
        } else {
            return '' + v;
        }
    }
}
