import { ChangeDetectorRef, Directive, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { mapTo, startWith } from 'rxjs/operators';



/**
 * Note: use 'shareReplay' for the observable passed to 'shLoading'
 */
@Directive({
    selector: '[shLoading]',
})
export class LoadingDirective implements OnInit, OnChanges, OnDestroy {
    @Input()
    shLoading: Observable<any | any[]>;

    private hasView = false;


    private _sub;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private chref: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this._updateProvider();
    }

    ngOnChanges(changes): void {
        if (changes.shLoading) {
            this._updateProvider();
        }
    }

    ngOnDestroy(): void {
        if (this._sub) {
            this._sub.unsubscribe();
            // this._sub = null;
        }
    }

    updateView(visible: boolean): void {
        if (visible && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
            setTimeout(() => {
                this.chref.markForCheck();
            });
        } else if (!visible && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
            setTimeout(() => {
                this.chref.markForCheck();
            });
        }
    }


    protected _updateProvider(): void {
        if (this._sub) {
            this._sub.unsubscribe();
            this._sub = null;
        }
        if (this.shLoading) {
            this.updateView(false); // hide existed view
            this._sub = this.shLoading.pipe(
                mapTo(false),
                startWith(true),
            ).subscribe({
                next: this.updateView.bind(this),
                error: this.updateView.bind(this, false),
                complete: this.updateView.bind(this, false),
            });
        } else {
            this.updateView(false);
        }
    }
}
