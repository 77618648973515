import { ErrorHandler, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

// import * as Sentry from '@sentry/browser';


@Injectable({providedIn: 'root'})
export class ShErrorHandler implements ErrorHandler {

  private prevMessage: string = null;

  constructor(private toastController: ToastController) {
  }

  handleError(err: any): void {

    // do something with the error
    console.error(err);

    // unfold promise rejection
    const errorObj = (err as any)?.rejection ? (err as any).rejection : err;

    //
    // Sentry.captureException(err.originalError || err);

    // don't show the same error many times
    const errorMessage = errorObj?.message || errorObj?.code || err || 'Unknown error';
    const canHideDuplicate = errorObj?.message || errorObj?.code;
    if (canHideDuplicate && errorMessage === this.prevMessage) {
      // same error message
      return;
    }
    this.prevMessage = errorMessage;

    this.toastController.create({
      message: errorMessage,
      duration: 1500,
      color: 'danger',
      buttons: ['Close'],
    }).then(alert => alert.present().then(() => alert.onWillDismiss())).then(() => {
      this.prevMessage = null;
    });
  }
}
