import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { RouteHelper } from "./helpers/router-helper";
import { AuthGuard } from "./shared/guards/auth.guard";
import { NotAuthGuard } from "./shared/guards/not-auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: RouteHelper.AUTH_PATH,
    pathMatch: "full",
  },
  {
    path: RouteHelper.AUTH_PATH,
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
    canActivate: [NotAuthGuard],
  },
  {
    path: RouteHelper.HOME_PATH,
    loadChildren: () =>
      import("./modules/main/main.module").then((m) => m.MainModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
