import { Pipe, PipeTransform } from '@angular/core';


/**
 */
@Pipe({name: 'hourstr', pure: true})
export class HourstrPipe implements PipeTransform {


    transform(value?: number): string | null {
        if ( !value) {
            return '0 hours';
        }
/*
        const days = Math.floor(value / 24);
        const hours = value % 24;

        if (days > 0) {
            return `${days} d, ${hours} h`;
        } else {
            return `${hours} hours`;
        }
*/
        // changed by markusin
        return `${value}`;
    }
}
