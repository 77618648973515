import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { map, take } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";

@Injectable()
export class NotAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    console.log("[ShNotAuthGuard] check");
    return this.authService.userId$.pipe(
      take(1),
      map((userId) => {
        if (userId) {
          console.log(userId);

          console.log("[ShNotAuthGuard] rejected: already authorised");
          return this.router.createUrlTree(["/home"]);
        }
        console.log("[ShNotAuthGuard] passed");
        return true;
      })
    );
  }
}
