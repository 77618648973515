import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { map, take } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { isWhitelisted } from './whitelist.util';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private navCtrl: NavController,
        private router: Router,
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('[ShAuthGuard] check');
        return this.authService.userId$.pipe(
            take(1),
            map(userId => {
                if (!userId) {
                    console.log('[ShAuthGuard] rejected: not authorised');
                    return this.router.createUrlTree(['/auth']);
                }
                console.log('[ShAuthGuard] passed');
                return true;
            }),
        );
    }
}
