import { Injectable } from "@angular/core";
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from "@angular/fire/storage";
import { AlertController, LoadingController, ToastController, } from "@ionic/angular";
import { finalize } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class SharedService {


    constructor(
        private _storage: AngularFireStorage,
        private _alertController: AlertController,
        private toastController: ToastController,
        private _loadingController: LoadingController,
        private translateService: TranslateService,
    ) {
    }

    getRefFromUrl(url: string): firebase.default.storage.Reference {
        return this._storage.storage.refFromURL(url);
    }

    uploadAndReturnUrl(file: File, folder?: string) {
        let storageRef: AngularFireStorageReference;
        let task: AngularFireUploadTask;

        if ( !folder) {
            storageRef = this._storage.ref(file.name);
            task = storageRef.put(file);
        } else {
            storageRef = this._storage.ref(`${ folder }/${ file.name }`);
            task = storageRef.put(file);
        }

        return new Promise<any>((resolve, reject) => {
            task
                .snapshotChanges()
                .pipe(
                    finalize(() =>
                        storageRef.getDownloadURL().subscribe(
                            (res) => resolve(res),
                            (err) => reject(err)
                        )
                    )
                )
                .subscribe();
        });
    }

    async load(light?: boolean): Promise<any> {
        return await this._loadingController.create({
            cssClass: 'my-loading-class',
            translucent: true,
            showBackdrop: false,
        });
    }

    async generalError(text: string) {
        const alert = await this._alertController.create({
            header: "Fehler!",
            message: text,
            buttons: ["OK"],
        });

        await alert.present();
    }

    async generalMessage(text: string) {
        const alert = await this._alertController.create({
            header: "Achtung",
            message: text,
            buttons: ["OK"],
        });

        await alert.present();
    }


    /**
     *
     */
    async toast(message: string) {
        const alert = await this.toastController.create({
            message: this.translateService.instant(message),
            duration: 1500,
            color: 'primary',
            buttons: ['Close'],
        });

        await alert.present();
    }

    async removeFile(filename: string): Promise<any> {
        return await this._storage.ref(filename).delete().toPromise();
    }
}
