import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingDirective } from './loading.directive';
import { NoDataDirective } from './no-data.directive';
import { ErrorDirective } from "./error.directive";


const sharedExports = [
    LoadingDirective,
    NoDataDirective,
    ErrorDirective,
];


@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: sharedExports,
    exports: [
        sharedExports,
    ],
})
export class ShLoadingModule {
}
