import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RouteHelper {
  public static readonly EMPTY_PATH = "";
  public static readonly ROOT_PATH = "/";

  public static readonly HOME_PATH = "home";
  public static readonly AUTH_PATH = "auth";
  public static readonly LOGIN_PATH = "login";
  public static readonly SUCC_PATH = "succ";
  public static readonly SIGNUP_PATH = "signup";

  public static readonly PROFILE_PATH = "profile";

  get rootUrl(): string[] {
    return [RouteHelper.ROOT_PATH];
  }

  get authUrl(): string[] {
    return [RouteHelper.ROOT_PATH, RouteHelper.AUTH_PATH];
  }

  get signUpUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.AUTH_PATH,
      RouteHelper.SIGNUP_PATH,
    ];
  }

  get loginUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.AUTH_PATH,
      RouteHelper.LOGIN_PATH,
    ];
  }

  get succUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.AUTH_PATH,
      RouteHelper.SUCC_PATH,
    ];
  }

  get homeUrl(): string[] {
    return [RouteHelper.ROOT_PATH, RouteHelper.HOME_PATH];
  }

  get profileUrl(): string[] {
    return [RouteHelper.ROOT_PATH, RouteHelper.HOME_PATH, RouteHelper.PROFILE_PATH];
  }
}
