import { ErrorHandler, Inject, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { AngularFireModule } from "@angular/fire";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "src/environments/environment";
import { AngularFirestore, AngularFirestoreModule } from "@angular/fire/firestore";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { SharedModule } from "./shared/shared.module";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, } from "@angular/fire/analytics";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ShErrorHandler } from "./error/ShErrorHandler";

import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from "@angular/common";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

registerLocaleData(localeIt);
registerLocaleData(localeDe);
registerLocaleData(localeEn);

const supportedLangs = ['en', 'it', 'de'];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({scrollAssist: true}),
        AppRoutingModule,

        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        AngularFireStorageModule,
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        AngularFirestoreModule,

        SharedModule.forRoot(),
        HttpClientModule,


        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },

            // compiler: {
            //     provide: TranslateCompiler,
            //     useClass: TranslateMessageFormatCompiler,
            //     // useClass: TranslateMessageFormatDebugCompiler, // DEBUG
            // },
            // missingTranslationHandler: {
            //   provide: MissingTranslationHandler,
            //   useClass: MyMissingTranslationHandler
            // },

        }),
    ],
    providers: [
        ScreenTrackingService,
        UserTrackingService,
        SplashScreen,
        ScreenOrientation,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: ErrorHandler, useClass: ShErrorHandler},
        {provide: LOCALE_ID, useFactory: myLocaleFactory, deps: [TranslateService]},
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        private translate: TranslateService,
        private firestore: AngularFirestore,
        @Inject(LOCALE_ID) private localeId: string,
    ) {
        this.firestore.firestore.settings({ignoreUndefinedProperties: true});

        // set fallback locale, i.e. when translation is not found, this language will be used as basis
        this.translate.setDefaultLang('en');

        // this.translate.use('de');
        this.translate.use(this.localeId);
    }
}

/**
 *
 */
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}


/**
 *
 */
export function myLocaleFactory(translate: TranslateService) {
    // return 'de';
    const userLang = translate.getBrowserLang();
    console.log('[LocaleFactory] user language:', userLang);
    if(supportedLangs.includes(userLang)){
        return userLang;
    }
    console.log('[LocaleFactory] user language is not supported, use default (en)');
    return 'en';
}
