import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ShProgressComponent } from "./progress/sh-progress.component";
import { InfiniteScrollExComponent } from "./infinite-scroll-list/infinite-scroll-ex.component";
import { ShLoadingComponent } from "./sh-loading/loading.component";
import { ShLoadingModule } from "../loading";


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        ShLoadingModule,
    ],
    declarations: [
        ShProgressComponent,
        InfiniteScrollExComponent,
        ShLoadingComponent,
    ],
    exports: [
        ShProgressComponent,
        InfiniteScrollExComponent,
        ShLoadingComponent,
    ],
    providers: [],
})
export class ShCommonIonModule {
}
