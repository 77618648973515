export const environment = {
  production: true,

  /*
  firebase: {
    apiKey: "AIzaSyBMOViwKACf0zSNK8cJcYbenlo4w9DBUuA",
    authDomain: "resch3-i40.firebaseapp.com",
    projectId: "resch3-i40",
    storageBucket: "resch3-i40.appspot.com",
    messagingSenderId: "1083563071795",
    appId: "1:1083563071795:web:01e6aeaa4c220effbd0646",
    measurementId: "G-0EHJ2QSYC2"
  }
  */

  firebase : {
    apiKey: "AIzaSyANew85-1Cez9JoZOrCPEf6BHWXDiaPeLg",
    authDomain: "solhearesch.firebaseapp.com",
    projectId: "solhearesch",
    storageBucket: "solhearesch.appspot.com",
    messagingSenderId: "623153956724",
    appId: "1:623153956724:web:6b31fb5ee7204aac6f0e21",
    measurementId: "G-3SGQFP5TEL"
}

};
