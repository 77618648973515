import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Timestamp } from "../interfaces/firebase/Timestamp";



/**
 * 'date' pipe which supports firebase Timestamp
 */
@Pipe({name: 'fbdate', pure: true})
export class FbDatePipe implements PipeTransform {

  private datePipe: DatePipe;

  constructor(
      @Inject(LOCALE_ID) private locale: string,
  ) {
    this.datePipe = new DatePipe(locale);
  }

  transform(value?: string | number | Date | Timestamp, format?: string, timezone?: string): string | null {
    if (!value) {
      return '';
    }
    if (typeof (value as Timestamp).toMillis === 'function') {
      value = (value as Timestamp).toMillis();
    }
    return this.datePipe.transform(value as Date, format, timezone);
  }
}
