import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';



/**
 * Note: use 'shareReplay' for the observable passed to 'shLoading'
 */
@Directive({
    selector: '[shNoData]',
})
export class NoDataDirective implements OnInit, OnChanges, OnDestroy {
    @Input()
    shNoData: Observable<any | any[]>;

    private hasView = false;


    private _sub;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private chref: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this._updateProvider();
    }

    ngOnChanges(changes): void {
        if (changes.shNoData) {
            this._updateProvider();
        }
    }

    ngOnDestroy(): void {
        if (this._sub) {
            this._sub.unsubscribe();
            // this._sub = null;
        }
    }

    updateView(visible: boolean): void {
        if (visible && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
            setTimeout(() => {
                this.chref.markForCheck();
            });
        } else if (!visible && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
            setTimeout(() => {
                this.chref.markForCheck();
            });
        }
    }


    protected _updateProvider(): void {
        if (this._sub) {
            this._sub.unsubscribe();
            this._sub = null;
        }
        if (this.shNoData) {
            let hasValue = false;
            this.updateView(false); // hide existed view
            this._sub = this.shNoData.pipe(
                // detect value
                map(v => {
                    if (v === null || v === undefined) {
                        // 'null' is 'no value'
                        return true;
                    }
                    if (Array.isArray(v)) {
                        // empty array
                        return !(v as any[]).length;
                    }
                    return false;
                }),
                tap(visible => {
                    hasValue = !visible;
                }),
            ).subscribe({
                next: this.updateView.bind(this),
                error: this.updateView.bind(this, true),
                complete: () => this.updateView(!hasValue),
            });
        } else {
            this.updateView(true);
        }
    }
}
