import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FirebaseCollectionHelper {
    public static readonly USERS = "users";
    public static readonly ORDER = "order";
    public static readonly ORDER_STATE = "orderState";
    public static readonly OWNER = "owner";
    public static readonly MACHINE = "machine";
    public static readonly MACHINE_MESSAGE = "machineMessage";
    public static readonly MACHINE_STATE = "machineState";
    public static readonly MACHINE_OPERATING_INFO = "machineOperatingInfo";
    public static readonly OPERATIONAL_STATE = "operationalState";
    public static readonly SYNCH_STATES = "synchState";

}
